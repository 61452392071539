import { type Table, flexRender } from "@tanstack/react-table";
import { stickToRight } from "../../../campaigns/MainPage/SharedTable/utils";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { CampaignsRowData } from "./index";

const Header = ({ table }: { table: Table<CampaignsRowData> }) => {
  const headerIdToFieldMap: Record<string, string> = {
    repoCount: "repoCount",
    userCount: "userCount",
    activeTeams: "activeTeams",
    activeContributors: "activeContributors",
    fullTimeDevs: "fullTimeDevs",
    partTimeDevs: "partTimeDevs",
    oneTimeDevs: "oneTimeDevs",
  };

  return (
    <thead className="sticky top-0 z-30 bg-offwhite pt-12">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const field = headerIdToFieldMap[header.column.id];
            const isFirstColumn = index === 0;
            const columnClasses = isFirstColumn ? stickToRight : "border-x";

            return (
              <th
                className={`p-0 text-left bg-grey-50 font-normal ${columnClasses}`}
                key={header.id}
              >
                <div className="w-full px-2 py-3">
                  {!header.isPlaceholder && (
                    <button
                      className={
                        header.column.getCanSort()
                          ? "flex select-none items-center justify-start gap-2"
                          : "flex"
                      }
                      onClick={() => field && header.column.toggleSorting()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <SortIcon
                        isSorted={!!header.column.getIsSorted()}
                        sortDirection={header.column.getIsSorted()}
                      />
                    </button>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

const SortIcon = ({
  isSorted,
  sortDirection,
}: {
  isSorted: boolean;
  sortDirection: "asc" | "desc" | false;
}) => {
  if (!isSorted) return <ChevronUpDownIcon className="w-4 h-4" />;

  return sortDirection === "asc" ? (
    <ChevronUpIcon className="w-4 h-4" />
  ) : (
    <ChevronDownIcon className="w-4 h-4" />
  );
};

export default Header;
