import {
  UsersIcon,
  ChartBarSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Dashboard from "~/components/campaigns/MainPage/Dashboard";
import { DependencyProvider } from "~/components/campaigns/MainPage/Dashboard/DashboardInsights/DependencyProviders";
import Settings from "~/components/campaigns/Settings";
import OverviewHeader from "~/components/layout/OverviewHeader";
import { useCampaigns } from "~/providers/CampaignsProvider";
import TargetInfoDashboard from "./campaigns/MainPage/TargetInfoDashboard";
import Stats from "./overview/Stats";
import SortParamsProvider from "~/providers/SortParamsProvider";
import Toast from "./campaigns/MainPage/Toast";
import { downloadAsToml, downloadTable } from "~/lib/utils";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import UnassignedUsersTable from "./UnassignedUsersTable";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";

const Overview = () => {
  const { campaigns } = useCampaigns();
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;
  const handleChangeSortOrder = () => {
    return null;
  };
  const headerMenuState = useState("Teams");
  const [, setHeaderMenuState] = headerMenuState;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingToml, setIsDownloadingToml] = useState(false);
  const repoSearchState = useState("");
  const userSearchState = useState("");
  const [repoSearch] = repoSearchState;
  const [userSearch] = userSearchState;
  const [activeHeaderMenu, setActiveHeaderMenu] = headerMenuState;
  const [showTable, setShowTable] = useState(true);
  const { data: session } = useSession();
  const router = useRouter();
  const wasClicked = router.query.overview === "true";
  const { query } = router;
  const tab = query?.tab as string;
  useEffect(() => {
    if (wasClicked) {
      setActiveHeaderMenu("Teams");
      router.replace({ pathname: "/" }).catch((err) => console.error(err));
    }
    if (tab) {
      setActiveHeaderMenu(tab);
    }
  }, [tab, setActiveHeaderMenu, wasClicked]);

  const { data: repoTargetsCount = 0 } =
    api.targetContact.overviewTargetCount.useQuery({
      teamAccountId: activeTeamAccountId,
      type: "repo",
    });
  const { data: userTargetsCount = 0 } =
    api.targetContact.overviewTargetCount.useQuery({
      teamAccountId: activeTeamAccountId,
      type: "user",
    });

  const getTargetStatus = () => {
    switch (headerMenuState[0]) {
      case "Teams":
        return repoTargetsCount === 0;
      case "Contributors":
        return userTargetsCount === 0;
      default:
        return false;
    }
  };
  const noTargets = getTargetStatus();

  const [teamAccountUser] = useTeamAccountUser();
  const hiddenHeaders = teamAccountUser?.role === "admin" ? [] : [];
  const sortOrders = [
    { name: "Teams", icon: UsersIcon, UsersIcon },
    { name: "Contributors", icon: UsersIcon, UsersIcon },
    { name: "Stats", icon: ChartBarSquareIcon },
    { name: "Unassigned discord users", icon: UserCircleIcon },
    ...hiddenHeaders,
  ];

  if (!campaigns || !session?.accessToken) return null;

  const eitherContributorsOrTeams =
    activeHeaderMenu === "Contributors" || activeHeaderMenu === "Teams";
  const handleDownloadTable = () => {
    setIsDownloading(true);
    downloadTable(activeHeaderMenu, activeTeamAccount?.id, "overview")
      .then(() => {
        setIsDownloading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadToml = () => {
    setIsDownloadingToml(true);
    downloadAsToml({
      activeHeaderMenu,
      teamAccountId: activeTeamAccount?.id,
      teamName: activeTeamAccount.name,
      tableName: "overview",
    })
      .then(() => {
        setIsDownloadingToml(false);
      })
      .catch((err) => console.error(err));
  };
  return (
    <SortParamsProvider userSearch={userSearch} repoSearch={repoSearch}>
      <OverviewHeader
        headerMenuType={"tabs"}
        newBtnText="New Campaign"
        newBtnHref={`/campaigns/new`}
        headerText={"Overview"}
        menuItems={sortOrders}
        headerMenuState={headerMenuState}
        handleChangeSortOrder={handleChangeSortOrder}
        hasTableToggle={eitherContributorsOrTeams}
        toggleTable={(val: boolean) => {
          setShowTable(val);
        }}
        downloadTable={handleDownloadTable}
        noTargets={noTargets}
        isDownloading={isDownloading}
        downloadToml={handleDownloadToml}
        isDownloadingToml={isDownloadingToml}
        showTable={showTable}
        searchState={
          eitherContributorsOrTeams
            ? activeHeaderMenu === "Contributors"
              ? userSearchState
              : repoSearchState
            : undefined
        }
      >
        <TargetInfoDashboard
          activeHeaderMenu={activeHeaderMenu}
          showTable={showTable}
        />
        {activeHeaderMenu === "Dashboard" && (
          <DependencyProvider>
            <Dashboard setSubMenu={setHeaderMenuState} />
          </DependencyProvider>
        )}

        {activeHeaderMenu === "Stats" && <Stats />}

        {activeHeaderMenu === "Settings" && <Settings />}

        {activeHeaderMenu === "Unassigned discord users" && (
          <UnassignedUsersTable />
        )}

        <Toast isDashboard={true} />
      </OverviewHeader>
    </SortParamsProvider>
  );
};
export default Overview;
