export const regularStyle = {
  height: 350,
  margin: 50,
  width: 800,
  stroke_opacity: 0.55,
  stroke_width: 1,
  stroke: "#A9A9A9",
  text_size: 12,
  text_fill: "#374151",
  text_opacity: 0.5,
  fill_opacity: { circle: 0.25, intersection: 0 },
};

export const tooltipStyle = {
  opacity: 0.9,
  fill_opacity: { circle: 0.4, intersection: 0.1 },
  stroke_opacity: 1,
  stroke_width: { circle: 2, intersection: 1 },
  stroke: "#fff",
  text_size: 14,
  text_opacity: 1,
  text_weight: "500",
  duration: 200,
};
