import {
  PresentationChartLineIcon,
  BoltIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useState } from "react";
import { getLocalDateString, validateDateFormat } from "~/lib/date";
import { useCampaigns } from "~/providers/CampaignsProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import Button from "../base/Button";
import Input from "../base/Input";
import Label from "../base/Label";
import { type CampaignMode } from "@openqlabs/drm-db";
import { Enums } from "@openqlabs/utils";

export default function New() {
  const router = useRouter();
  const { activeTeamAccount } = useTeamAccount();
  const [teamAccountUser] = useTeamAccountUser();
  const { handleRefetch } = useCampaigns();
  const logger = useLogger();

  const sixWeeksAgo = getLocalDateString(
    new Date(new Date().setDate(new Date().getDate() - 42))
  );

  const [campaignName, setCampaignName] = useState("");
  const [startDate, setStartDate] = useState(sixWeeksAgo);
  const [endDate, setEndDate] = useState("");
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);
  const [campaignMode, setCampaignMode] = useState<CampaignMode>(
    Enums.CampaignMode.LIGHT
  );

  const createCampaign = api.campaign.campaignCreate.useMutation({
    onSuccess: async (data) => {
      await router
        .push(`/campaigns/${data.id}/dependency-tracking`)
        .catch((err) => logger.error(err, "New.tsx"))
        .then(() => {
          handleRefetch();
        });
    },
  });

  function handleAddCampaign() {
    createCampaign.mutate({
      name: campaignName,
      targetIds: [],
      teamAccountId: activeTeamAccount.id,
      userId: teamAccountUser?.id as string,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      mode: campaignMode,
    });
  }

  const handleDashes = (value: string, dateString: string) => {
    let workingValue = value;
    if (value.length === 4 || value.length === 7) {
      if (dateString.length < value.length) {
        workingValue = value + "-";
      } else {
        workingValue = value.slice(0, -1);
      }
    }
    return workingValue;
  };

  const handleStartDateChange = (value: string) => {
    const workingValue = handleDashes(value, startDate);

    const isValid = validateDateFormat(workingValue);
    if (workingValue) setStartDateValid(isValid);
    else setStartDateValid(false);
    setStartDate(workingValue);
  };
  const handleEndDateChange = (value: string) => {
    const workingValue = handleDashes(value, endDate);
    const isValid = validateDateFormat(workingValue);
    setEndDateValid(isValid);
    setEndDate(workingValue);
  };

  const switchCampaignMode = (mode: CampaignMode) => {
    if (mode !== Enums.CampaignMode.DEEP) {
      setStartDate(sixWeeksAgo);
      setEndDate("");
    }
    setCampaignMode(mode);
  };

  return (
    <div className="mx-auto w-full max-w-[1200px]">
      <div className="mt-6 flex content-center items-center pb-4">
        <PresentationChartLineIcon className="sm-icon mr-2" />
        <span className="flex-1 text-2xl font-semibold">
          Create a new campaign
        </span>
      </div>
      <p className="mt-6 text-lg">
        Start a new campaign to track Github contacts, organize your
        interactions, and monitor dependencies.
      </p>
      <div className="flex flex-col gap-6 border-b border-divider py-6 pb-10">
        <div>
          <Input
            value={campaignName}
            setValue={setCampaignName}
            placeholder="My campaign name"
          />
        </div>
        <div>
          <Label>
            {campaignMode === Enums.CampaignMode.DEEP
              ? "Deep Mode"
              : campaignMode === Enums.CampaignMode.LIGHT
                ? "Light Mode"
                : "Ultra Light Mode"}
          </Label>
          <p className="pb-4">
            {campaignMode === Enums.CampaignMode.ULTRALIGHT ? (
              <>
                Ultra Light mode will analyze all repositories provided, but
                none of the other contributors' repositories. Useful when you
                only want to analyze activity for a list of repositories.
              </>
            ) : campaignMode === Enums.CampaignMode.LIGHT ? (
              <>
                Light mode conducts a quicker analysis of a large number of
                teams and contributors. It analyzes repositories committed to
                most recently, providing a balance between speed and depth.
              </>
            ) : (
              <>
                Deep mode activates a comprehensive analysis, designed for
                smaller groups of developers. It conducts thorough backtracking
                through GitHub repositories, facilitating the identification of
                when developers joined your ecosystem.
                <br />
                <br />
                We can trace events up to three years in the past, examining
                commits in GitHub repositories to determine when users committed
                a dependency to their project that we're tracking for you, or
                participated in a project containing your dependency.
              </>
            )}
          </p>
          <div className="flex">
            <Button
              className={`${
                campaignMode !== Enums.CampaignMode.ULTRALIGHT
                  ? "bg-gray-300 text-black hover:text-white"
                  : ""
              } rounded-r-none`}
              onClick={() => switchCampaignMode(Enums.CampaignMode.ULTRALIGHT)}
            >
              <BoltIcon className="sm-icon mr-2" />
              Ultra Light
            </Button>
            <Button
              className={`${
                campaignMode !== Enums.CampaignMode.LIGHT
                  ? "bg-gray-300 text-black hover:text-white"
                  : ""
              } rounded-none`}
              onClick={() => switchCampaignMode(Enums.CampaignMode.LIGHT)}
            >
              <BoltIcon className="sm-icon mr-2" />
              Light
            </Button>
            <Button
              className={`${
                campaignMode !== Enums.CampaignMode.DEEP
                  ? "bg-gray-300 text-black hover:text-white"
                  : ""
              } rounded-l-none`}
              onClick={() => switchCampaignMode(Enums.CampaignMode.DEEP)}
            >
              <ClockIcon className="sm-icon mr-2" />
              Deep
            </Button>
          </div>
        </div>
        {campaignMode == Enums.CampaignMode.DEEP && (
          <>
            {" "}
            <div>
              <Label>Start Date</Label>
              <Input
                placeholder="YYYY-MM-DD"
                value={startDate}
                setValue={handleStartDateChange}
              />
              {!startDateValid && (
                <div className="text-red-500">Start Date is invalid</div>
              )}
            </div>
            <div>
              <Label>End Date</Label>
              <Input
                placeholder="YYYY-MM-DD"
                value={endDate}
                setValue={handleEndDateChange}
              />
              {!endDateValid && (
                <div className="text-red-500">End Date is invalid</div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="mt-6">
        <Button
          onClick={handleAddCampaign}
          disabled={
            campaignName.length === 0 || !startDateValid || !endDateValid
          }
          className="ml-auto px-8"
        >
          Create
        </Button>
      </div>
    </div>
  );
}
