import VennDiagram from "./VennDiagram";
import ComparisonTable from "./ComparisonTable/index";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { Constants } from "@openqlabs/utils";
import { InsightsPerTracker, InsightsObject } from "@openqlabs/drm-db";
import { api, type RouterOutputs } from "~/utils/api";
import { useCampaigns } from "~/providers/CampaignsProvider";
import SortParamsProvider from "~/providers/SortParamsProvider";

// for examples of venn.js: https://github.com/benfred/venn.js

type CampaignStats =
  RouterOutputs["campaignEvaluation"]["getAllCampaignsDashboardStats"];

export default function Stats() {
  const { activeTeamAccount } = useTeamAccount();
  const { data: overviewEvaluation } = api.overviewEvaluation.get.useQuery({
    teamAccountId: activeTeamAccount.id,
  });

  const dataSet = overviewEvaluation?.vennDataSet ?? [];
  const tooMuchData = dataSet.length > Constants.MAX_ARRAY_SIZE_VENN;
  const { campaigns } = useCampaigns();
  const campaignIds = campaigns?.map((campaign) => campaign.id) ?? [];

  const dashboardStats =
    api.campaignEvaluation.getAllCampaignsDashboardStats.useQuery({
      teamAccountId: activeTeamAccount.id,
    }).data as CampaignStats;
  const dashboardStatsPrep = dashboardStats
    ?.filter((x) => campaignIds.includes(x.campaignId))
    ?.reduce(
      (acc, statsCampaign) => {
        const tracker = campaigns?.find(
          (campaign) => campaign.id === statsCampaign.campaignId
        )?.trackers?.[0];
        const trackerId = tracker?.id;
        const dependency = tracker?.dependencies?.[0];
        const insightsPerTracker =
          statsCampaign?.insightsPerTracker as InsightsPerTracker | null;

        const insights = (trackerId &&
          dependency &&
          insightsPerTracker?.[trackerId]?.[
            dependency
          ]) as InsightsObject | null;

        acc[statsCampaign.campaignId] = {
          campaignId: statsCampaign.campaignId,
          insights: insights ?? null,
          waitingEvalsCount: statsCampaign.waitingEvalsCount ?? 0,
          doneEvalsCount: statsCampaign.doneEvalsCount ?? 0,
          repoCount: statsCampaign.repoCount ?? 0,
          userCount: statsCampaign.userCount ?? 0,
          status: statsCampaign.status,
          updatedAt: statsCampaign.updatedAt,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          campaignId: string;
          insights: InsightsObject | null;
          waitingEvalsCount: number;
          doneEvalsCount: number;
          repoCount: number;
          userCount: number;
          status: string;
          updatedAt: Date;
        }
      >
    );

  const totalEvalsCount = Object.values(dashboardStatsPrep ?? {})?.reduce(
    (acc, campaign) => {
      acc.waitingEvalsCount += campaign.waitingEvalsCount;
      acc.doneEvalsCount += campaign.doneEvalsCount;
      return acc;
    },
    { waitingEvalsCount: 0, doneEvalsCount: 0 }
  );

  const percentageDone =
    totalEvalsCount.doneEvalsCount /
    (totalEvalsCount.waitingEvalsCount + totalEvalsCount.doneEvalsCount);
  const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;

  return (
    <div className="h-[calc(100vh_-_270px)] overflow-scroll">
      <SortParamsProvider userSearch="" repoSearch="">
        <ComparisonTable
          dashboardStatsPrep={dashboardStatsPrep}
          campaigns={campaigns}
        />
        {!tooMuchData && (
          <VennDiagram
            overviewEvaluation={overviewEvaluation}
            considerDone={considerDone}
          />
        )}
      </SortParamsProvider>
    </div>
  );
}
