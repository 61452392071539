import * as d3 from "d3";
import * as venn from "venn.js";
import type { SVGStyle, TooltipStyle } from "./types";
import type { VennData } from "@openqlabs/drm-db";

export default function createVennDiagram(
  data: VennData[],
  style: SVGStyle,
  tooltipStyle: TooltipStyle
) {
  const svg_venn = d3
    .create("svg")
    .attr("viewBox", [0, 0, style.width, style.height]);
  svg_venn.datum(data).call(venn.VennDiagram());

  // Optional: Edit Stroke
  svg_venn
    .selectAll(".venn-circle path")
    .style("stroke", style.stroke)
    .style("stroke-opacity", style.stroke_opacity)
    .style("stroke-width", style.stroke_width);

  // Optional: Edit Text
  svg_venn
    .selectAll(".venn-circle text, .venn-intersection text")
    .style("font-size", style.text_size)
    .style("fill", style.text_fill)
    .style("opacity", style.text_opacity);

  const tooltip = d3.select("body").append("div").attr("class", "tooltip");

  svg_venn
    .selectAll("g")
    .on("mouseover", function (event, data) {
      // sort all the areas relative to the current item
      const dataSample = data as VennData;
      venn.sortAreas(svg_venn, dataSample);

      // Display a tooltip with the current size
      if (dataSample.tooltip !== "no overlap") {
        tooltip
          .transition()
          .duration(tooltipStyle.duration)
          .style("opacity", tooltipStyle.opacity);
      }
      tooltip.text(dataSample.tooltip);

      // highlight the current path
      const selection = d3
        .select(this)
        .transition("tooltip")
        .duration(tooltipStyle.duration);
      selection
        .select("path")
        .style(
          "stroke-width",
          dataSample.sets.length == 1
            ? tooltipStyle.stroke_width.circle
            : tooltipStyle.stroke_width.intersection
        )
        .style("stroke", tooltipStyle.stroke)
        .style("stroke-opacity", tooltipStyle.stroke_opacity)
        .style(
          "fill-opacity",
          dataSample.sets.length == 1
            ? tooltipStyle.fill_opacity.circle
            : tooltipStyle.fill_opacity.intersection
        );
      selection
        .select("text")
        .style("opacity", tooltipStyle.text_opacity)
        .style("font-size", tooltipStyle.text_size)
        .style("font-weight", tooltipStyle.text_weight) // Changed from "bold" to a numerical value representing a medium font weight
        .transition()
        .duration(tooltipStyle.duration);
    })

    .on("mousemove", function (event) {
      tooltip
        .style("left", event.clientX + "px")
        .style("top", event.clientY - 28 + "px");
    })

    .on("mouseout", function (event, data) {
      const dataSample = data as VennData;
      tooltip.transition().duration(tooltipStyle.duration).style("opacity", 0);
      const selection = d3
        .select(this)
        .transition("tooltip")
        .duration(tooltipStyle.duration);
      selection
        .select("path")
        .style(
          "fill-opacity",
          dataSample.sets.length == 1
            ? style.fill_opacity.circle
            : style.fill_opacity.intersection
        )
        .style("stroke", style.stroke)
        .style("stroke-opacity", style.stroke_opacity)
        .style("stroke-width", style.stroke_width);
      selection
        .select("text")
        .style("opacity", style.text_opacity)
        .style("font-size", style.text_size)
        .style("font-weight", "normal")
        .transition()
        .duration(tooltipStyle.duration);
    });

  return svg_venn.node();
}
